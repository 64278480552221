import React, { memo } from "react";
import { Dropdown, } from 'semantic-ui-react';

const areEqual = (prevProps, nextProps) => {
  for (let key in nextProps) {
    if (!_.isEqual(prevProps[key], nextProps[key])) {
      return false;
    }
  }
  return true;
};

const DropdownMemoized = memo((props) => {
  return <Dropdown
    {...props}
  />
}, areEqual);

export default DropdownMemoized;
