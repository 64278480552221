class MockTwilioCall {
  constructor() {
    this.listeners = {};
    this.parameters = {}
    this.customParameters = new Map()
  }

  on(event, callback) {
    this.listeners[event] = callback;
  }

  off(event) {
    delete this.listeners[event];
  }

  mute() {}

  makeCall(params) {
    if (this.listeners['accept']) {
      setTimeout(() => this.listeners['accept'](), 100);
    }
    return Promise.resolve({
      status: 'connected',
      params,
    });
  }

  triggerIncomingCall(from) {
    if (this.listeners['incoming']) {
      const mockCall = new MockTwilioCall();
      this.listeners['incoming']({ from, call: mockCall });
    }
  }

  accept() {
    if (this.listeners['accept']) {
      this.listeners['accept']()
    }
  }

  disconnect() {
    if (this.listeners['disconnect']) {
      this.listeners['disconnect']();
    }
  }

  triggerError(errorMessage) {
    if (this.listeners['error']) {
      this.listeners['error'](new Error(errorMessage));
    }
  }
}

export default MockTwilioCall;
