import _ from "underscore"
import { Form, Popup, Divider, } from 'semantic-ui-react';
import ThreadHistory from "./ThreadHistory.js";
import ThreadHistoryPopup from './ThreadHistoryPopup.js';

import React from "react";
import moment from "moment";

export function present(x) {
  if (typeof x === "object" && _.isEmpty(x)) return false;
  if (Array.isArray(x) && x.length == 0) return false;
  if (typeof x === "undefined" || !x || (typeof x === "String" && x.trim() == "") || x == null) return false;
  return true;
}

export function errorFor(model, columnName) {
  if (!present(model) || !present(model.errors)) return null;
  let errors = errorsFor(model, columnName)
  if (columnName.includes("Id")) {
    errors = errors.concat(errorsFor(model, columnName.replace("Id", "")));
  }
  if (errors.length === 0) return null;
  return errors.join(", ");

  function errorsFor(model, columnName) {
    if (present(model.errors[columnName])) {
      return model.errors[columnName]
    }
    return [];
  }
}

export function selectGenderedSalutes(genderedSalutes) {
  let mappedSalutes = genderedSalutes.map((s,i) => {
    return {
      key: s.key,
      value: s.key+(s.uiAppend||""),
      text: `${s.male}/${s.female}`,
    }
  });
  mappedSalutes.push({
    key: "#firstName#",
    value: "#firstName#",
    text: "first name",
  });
  mappedSalutes.push({
    key: "#lastName#",
    value: "#lastName#",
    text: "last name",
  });
  mappedSalutes.push({
    key: "#ourFirstName#",
    value: "#ourFirstName#",
    text: "our first name",
  });
  mappedSalutes.push({
    key: "#ourFirstNameShort#",
    value: "#ourFirstNameShort#",
    text: "our first name short",
  });
  mappedSalutes.push({
    key: "#ourLastName#",
    value: "#ourLastName#",
    text: "our last name",
  });
  return mappedSalutes;
}

export function formInput(model, name, label, onChange, Element = Form.Input, props, htmlName) {
  let valueProp = { value: model[name] || "" }
  if (Element == Form.Checkbox) {
    valueProp = { checked: model[name] }
  }
  if (Element == Form.Select) {
    if (!props.multiple) {
      props = { ...props, clearSearchQueryAfterItemAdd: typeof props.clearSearchQueryAfterItemAdd === "undefined" ? true : props.clearSearchQueryAfterItemAdd }
    }
  }
  return (
    <Element
      error={errorFor(model, name)}
      label={label}
      {...valueProp}
      onChange={adaptOnChangeToClassicFormOnChange(onChange, htmlName)}
      name={htmlName || name}
      {...props}
    />
  );
}

export function adaptOnChangeToClassicFormOnChange(formOnChange, nameOverwrite) {
  if (nameOverwrite) {
    return (_, data) => { return formOnChange( { target: {...data, name: nameOverwrite, } } ) };
  } else {
    return (_, data) => { return formOnChange( { target: {...data, } } ) };
  }
}

export function l(date) {
  return moment(date).format("DD.MM.YYYY")
}

export function renderProspectCampaignAssocForTableView(assoc, prospect, i, position="right", handlePopupActivation = null, activePopup = null) {
  if (!present(assoc.linkedInOutreach)) return null;
  if (!present(assoc.linkedInOutreach.threadUrl)) return null;
  if (assoc.linkedInOutreach.repliedVia === "email") {
    return (
      <div key={i}>
        <a href={`https://mail.google.com/mail/u/0/#inbox/${assoc.linkedInOutreach.emailThreadId}`} target="_blank">{ assoc.campaign.linkedInAccount.name } (EMAIL REPLIED)</a>
      </div>
    );
  }

  let historyContent
  if (present(assoc.linkedInOutreach.threadHistory)) {
    historyContent = (<ThreadHistory
      history={assoc.linkedInOutreach.threadHistory}
      linkedInAccount={assoc.campaign.linkedInAccount}
      prospect={prospect}
      visible={true}
    />)
  } else {
    historyContent = assoc.linkedInOutreach.linkedInOutreachThreads.map((thread, i) => {
      let threadHistory = thread.linkedInThread.threadHistory
      return (
        <div>
          <ThreadHistory
            threadId={thread.linkedInThread.id}
            key={i}
            history={threadHistory}
            linkedInAccount={assoc.campaign.linkedInAccount}
            prospect={prospect}
            visible={true}
            sticky={true}
          >
            {i < assoc.linkedInOutreach.linkedInOutreachThreads.length - 1 && (
              <Divider />
            )}
          </ThreadHistory>
        </div>
      )
    })
  }

  return (
    <div key={i}>
      <ThreadHistoryPopup
        assoc={assoc}
        prospect={prospect}
        key={i}
        index={i}
        activePopup={activePopup}
        onPopupActivate={handlePopupActivation}
        displayOn={position}
        offsetText={assoc.campaign.linkedInAccount.name}
      >
        <a href={assoc.linkedInOutreach.threadUrl} target="_blank" style={{ display: "inline-block" }}>
          {assoc.campaign.linkedInAccount.name}
          {present(assoc.linkedInOutreach.repliedAt) ? " (REPLIED)" : ""}
        </a>
      </ThreadHistoryPopup>
    </div>
  )

}

export function filterObject(obj, key) {
  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (i == key) {
      delete obj[key];
    } else if (typeof obj[i] == 'object') {
      filterObject(obj[i], key);
    }
  }
}


export function customPromptVariablesSelector(backendData) {
    return backendData.map(v => {
      return {
        key: v.name,
        value: v.value,
        text: v.name,
        shortcut: v.shortcut,
      }
    });
  }

