import React, { useEffect, useState, useRef } from "react";
import { Form, Grid, Checkbox, Divider, Dropdown, Message, Segment } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import MotionDatePicker from "../MotionDatePicker.js";
import { present, } from "../utils.js";
import CrmStatusHistory from "./CrmStatusHistory.js";

const AdvancedFilters = (props = {}) => {
  let query = props.query || {};

  const prevQueryRef = useRef(query);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(query.remained_in_status_date || query.previous_enrichments || query.withoutEmailAddress || query.withoutPhoneNumber || query.crmStatusHistory || false);

  let dates = props.dates || {};
  let { remained_in_status_date, remained_in_status_date_from, previous_enrichments } = query;
  previous_enrichments ||= ""

  const toggleAdvancedFilters = (e) => {
    e.preventDefault();
    setShowAdvancedFilters(!showAdvancedFilters)
  }

  useEffect(() => {
    if (Object.keys(prevQueryRef.current).length == 0 && Object.keys(props.query).length > 0) {
      setShowAdvancedFilters(query.remained_in_status_date || query.previous_enrichments || query.withoutEmailAddress || query.withoutPhoneNumber || query.crmStatusHistory)
    }
    prevQueryRef.current = props.query;
  }, [props.query])

  return (
    <>
      {props.currentUserCanSeeProspectDetails && (
        <Grid.Column width={6} style={{ paddingBottom: 0 }}>
          <Form.Field style={{ display: 'inline-block', marginRight: 20 }}>
            <Checkbox
              label='With Email Address'
              onChange={(_, v) => props.handleWithEmailAddressFilterChange(v)}
              checked={query.withEmailAddress || false}
            />
          </Form.Field>
          <Form.Field style={{ display: 'inline-block', marginRight: 20 }}>
            <Checkbox
              label='With Phone Number'
              onChange={(_, v) => props.handleWithPhoneNumberFilterChange(v)}
              checked={query.withPhoneNumber || false}
            />
          </Form.Field>
        </Grid.Column>
      )}

      <Grid.Column width={props.currentUserCanSeeProspectDetails ? 10 : 16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Link to="#" onClick={e => toggleAdvancedFilters(e)}>
            {showAdvancedFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
          </Link>
        </div>
      </Grid.Column>

      { showAdvancedFilters && (
        <>
          <Grid.Column width={16} style={{ marginBottom: 5 }}>
            <Divider />
          </Grid.Column>

          { (props.currentUserCanSeeDataProviderData || props.currentUserCanSeeProspectDetails) && (
            <Grid.Column width={16}>
              <Segment>
                <h3>Enrichment</h3>
                <Grid>
                  {props.currentUserCanSeeDataProviderData && (
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <MotionDatePicker
                          label="Reached current CRM status before"
                          range={remained_in_status_date || 'all'}
                          from={remained_in_status_date_from}
                          onChange={props.handleRemainedInCurrentCrmStatusDateFilterChange}
                          rangeDatepicker={false}
                          options={[
                            { key: 'all', value: 'all', text: `all` },
                            { key: 'this_week', value: 'this_week', text: `This week (${dates.thisWeek})` },
                            { key: 'last_week', value: 'last_week', text: `Last week (${dates.lastWeek})` },
                            { key: 'this_month', value: 'this_month', text: `This month (${dates.thisMonth})` },
                            { key: 'last_month', value: 'last_month', text: `Last month (${dates.lastMonth})` },
                            // { key: 'this_quarter', value: 'this_quarter', text: `This quarter (${dates.thisQuarter})` },
                            { key: 'last_quarter', value: 'last_quarter', text: `Last quarter (${dates.lastQuarter})` },
                            { key: 'this_year', value: 'this_year', text: `This year (${dates.thisYear})` },
                            { key: 'last_year', value: 'last_year', text: `Last year (${dates.lastYear})` },
                            { key: 'custom', value: 'custom', text: `Custom` },
                          ]}
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Field>
                          <label>Not part of previous enrichment</label>
                          <Dropdown
                            fluid
                            selection
                            multiple
                            search
                            value={previous_enrichments.split(',').map(id => parseInt(id))}
                            options={
                              props.prospectsEnrichments.map(e => (
                                { key: e[0], value: e[0], text: e[1] }
                              ))}
                            labeled
                            onChange={(_, data) => props.handlePreviousEnrichmentsFilterChange(data.value)}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  { props.currentUserCanSeeProspectDetails && (
                    <Grid.Row style={{ paddingTop: props.currentUserCanSeeDataProviderData ? '1rem' : 0 }}>
                      <Grid.Column width={6} style={{ paddingBottom: 0 }}>
                        <Form.Field style={{ display: 'inline-block', marginRight: 20 }}>
                          <Checkbox
                            label='Without Email Address'
                            onChange={(_, v) => props.handleWithoutEmailAddressFilterChange(v)}
                            checked={query.withoutEmailAddress === 'true'}
                          />
                        </Form.Field>
                        <Form.Field style={{ display: 'inline-block', marginRight: 20 }}>
                          <Checkbox
                            label='Without Phone Number'
                            onChange={(_, v) => props.handleWithoutPhoneNumberFilterChange(v)}
                            checked={query.withoutPhoneNumber === 'true'}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Segment>
            </Grid.Column>
          )}

          <Grid.Column width={16}>
            <CrmStatusHistory
              crmStatusHistory={props.crmStatusHistory}
              handleResetCrmStatusHistoryFilter={props.handleResetCrmStatusHistoryFilter}
              crmStatuses={props.crmStatuses}
              campaigns={props.campaigns}
              users={props.users}
              companyId={props.companyId}
            />
          </Grid.Column>
        </>
      )}
    </>
  );
}

export default AdvancedFilters;
