import React from 'react';
import axios from 'axios';
import moment from 'moment';

const PROSPECT_DISTRIBUTION_FLASH = 'Prospects are being distributed, please wait a few minutes and try again.'

const fetchCall = async (requestPath, props, setScheduledPhoneCall, setPhonesAndSelectedDataProviderInformation, setSaveDisabled) => {
  try {
    const response = await axios.get(requestPath);
    if (response.status === 204) {
      props.history.push(`/cold_calling`, { flash: 'Not found.' })
    } else {
      setScheduledPhoneCall({
        ...response.data,
        blacklistProspect: '',
      })
      setPhonesAndSelectedDataProviderInformation(response.data)
    }
  } catch (error) {
    if (error.response.status == 307 && error.response.data.status == 'prospect_distribution_running') {
      props.history.replace(`/cold_calling`, { flash: PROSPECT_DISTRIBUTION_FLASH })
    } else if (error.response.status == 307 && error.response.data.status == 'scheduled_phone_call_locked') {
      props.history.replace(`/cold_calling`, { flash: "You cannot access this scheduled phone call because someone else is currently working on it." })
    } else {
      console.error('Failed to fetch call:', error, error.response.status, error.response.data);
    }
  } finally {
    setSaveDisabled(false)
  }
}

const handleSave = async (callType, campaignGroupId, scheduledPhoneCall, setDisplayErrors, pageChangeBlocker, fetchNextCall, callInProgress, setSaveDisabled) => {
  setSaveDisabled(true)
  let phoneCalls = scheduledPhoneCall.phoneCalls.filter(call => call.scheduledPhoneCallId == scheduledPhoneCall.id)
  let saveData = {
    notes: scheduledPhoneCall.notes,
    phoneCallOutcomeId: scheduledPhoneCall.phoneCallOutcomeId,
    scheduledFor: scheduledPhoneCall.prospect && scheduledPhoneCall.callbackTime,
    blacklistProspect: scheduledPhoneCall.blacklistProspect || false,
    callbackDistributionStrategy: scheduledPhoneCall.callbackDistributionStrategy,
    futureScheduledPhoneCalls: scheduledPhoneCall.futureScheduledPhoneCalls,
    phoneCallsAttributes: phoneCalls.map(call => ({
      id: call.id,
      connected: call.connected,
      companyOk: call.companyOk,
      personOk: call.personOk,
      gatekeeper: call.gatekeeper,
      gotThrough: call.gotThrough,
    }))
  }
  try {
    let requestPath
    if (callType === 'outgoing') {
      requestPath = `/cold_calling/${campaignGroupId}/${scheduledPhoneCall.id}`
    } else {
      requestPath = `/cold_calling/incoming/${scheduledPhoneCall.id}`
    }
    let response = await axios.post(requestPath, { scheduledPhoneCall: saveData });
    let data = response.data;
    if (data.success) {
      setDisplayErrors(false)
      if (pageChangeBlocker.current) {
        window.removeEventListener("beforeunload", pageChangeBlocker.current[0]);
        pageChangeBlocker.current = null;
      }
      if (!callInProgress) {
        fetchNextCall();
      } else {
        setSaveDisabled(false)
      }
    } else {
      reloadScheduledPhoneCallPhoneCalls()
      setDisplayErrors(true)
      setSaveDisabled(false)
    }
  } catch (error) {
    setSaveDisabled(false)
    console.error('Failed to save prospect:', error);
  }
};

const reloadScheduledPhoneCallPhoneCalls = async (requestPath, reloading, scheduledPhoneCall, setScheduledPhoneCall, setLoading) => {
  // Prevent multiple reloads at the same moment as they'd negate the [callData, loading] useEffect
  if (reloading && !reloading.current) {
    reloading.current = true
  } else {
    return;
  }

  setLoading(true)

  try {
    const response = await axios.get(requestPath);
    let scheduledPhoneCallPhoneCalls = scheduledPhoneCall.phoneCalls
    response.data.phoneCalls.forEach(call => {
      let phoneCall = scheduledPhoneCallPhoneCalls.find(c => c.id == call.id)
      if (!phoneCall) {
        scheduledPhoneCallPhoneCalls = [call].concat(scheduledPhoneCallPhoneCalls)
      } else {
        phoneCall.duration = call.duration
      }
    })
    setScheduledPhoneCall((prevScheduledPhoneCall) => ({ ...prevScheduledPhoneCall, phoneCalls: scheduledPhoneCallPhoneCalls }))
  } catch (error) {
    console.error('Failed to reload scheduled phone call:', error);
  }

  reloading.current = false
  setLoading(false)
};

const createNotesFromPreviousCalls = (scheduledPhoneCall, previousScheduledPhoneCalls, outcomes) => {
  return previousScheduledPhoneCalls.map(spc => {
    let scheduledPhoneDate = moment(spc.completedAt || spc.scheduledFor || scheduledPhoneCall.createdAt).format('DD.MM.YYYY')
    let outcomeText = spc.phoneCallOutcomeId && outcomes.find(outcome => outcome.id === spc.phoneCallOutcomeId)?.name
    outcomeText ||= 'Callback'

    if (Number(spc.id) == Number(scheduledPhoneCall.id)) return null
    if (!spc.notes || spc.notes.length === 0) return null

    return (
      <p key={spc.id} style={{ marginBottom: 10 }}>
        <i>{scheduledPhoneDate}, {outcomeText}:</i><br></br> {spc.notes}
      </p>
    )
  }).filter(x => x)
}

export { PROSPECT_DISTRIBUTION_FLASH, fetchCall, handleSave, reloadScheduledPhoneCallPhoneCalls, createNotesFromPreviousCalls };
