import React, { useEffect } from 'react'
import { Dropdown, Form, Icon, Popup, Segment, TextArea } from 'semantic-ui-react'
import MotionDatePicker from '../../../shared/MotionDatePicker';
import { present } from "../../../shared/utils.js";
import moment from 'moment';

const ScheduledPhoneCallControls = ({ scheduledPhoneCall, setScheduledPhoneCall, displayErrors, outcomes, scheduledPhoneCallId }) => {
  const cancelledOutcome = outcomes.find(outcome => outcome.name == 'Cancelled')
  const callbackScheduledPhoneCall = (scheduledPhoneCall.scheduledPhoneCalls || []).filter(callbackSpc =>
    callbackSpc.createdById == scheduledPhoneCall.id && callbackSpc.phoneCallOutcomeId != cancelledOutcome.id
  )[0]
  const callbackScheduledPhoneCallPhoneCalls = scheduledPhoneCall.phoneCalls.filter(pc => pc.scheduledPhoneCallId == callbackScheduledPhoneCall?.id)

  let callbackHandledPopup
  let callbackHandled = present(callbackScheduledPhoneCall?.completedAt) || callbackScheduledPhoneCallPhoneCalls.length > 0
  if (callbackHandled) {
    callbackHandledPopup = (<Popup
      content="Callback has been handled and cannot be modified."
      position="top center"
      trigger={<Icon name='info circle' color='blue' style={{ marginLeft: 5 }} />}
      on="hover"
    />)
  }

  const handleCallbackTimeChange = (date) => {
    let callbackTime
    if (date.range !== 'custom' && date.range !== 'no_callback') {
      callbackTime = date.range
    } else if (date.range === 'custom') {
      callbackTime = date.from
    } else {
      callbackTime = null
    }

    setScheduledPhoneCall((prevScheduledPhoneCall) => ({ ...prevScheduledPhoneCall, callbackTime: callbackTime }));
  };

  const handleScheduledPhoneCallChange = (e, { name, value }) => {
    let newCallbackTime = scheduledPhoneCall.callbackTime
    if (name === "phoneCallOutcomeId" && scheduledPhoneCall.prospect) {
      let outcome = outcomes.find(o => o.id === value)
      if (["Callback", "VIP Callback"].includes(outcome.name)) {
        if (newCallbackTime == 'no_callback') { newCallbackTime = 'in_3_days' }
      } else {
        if (['in_3_days', 'in_7_days'].includes(newCallbackTime)) { newCallbackTime = 'no_callback' }
      }
    }
    setScheduledPhoneCall((prevScheduledPhoneCall) => ({ ...prevScheduledPhoneCall, [name]: value, callbackTime: newCallbackTime, }));
  };

  let futureScheduledPhoneCalls
  if (scheduledPhoneCall.scheduledPhoneCalls) {
    futureScheduledPhoneCalls = scheduledPhoneCall.scheduledPhoneCalls
      .filter(s => Number(s.id) !== Number(scheduledPhoneCallId))
      .filter(s => {
        if (s.completedAt) return false
        return moment(s.scheduledFor).isAfter(moment())
      });
  } else {
    futureScheduledPhoneCalls = []
  }

  useEffect(() => {
    if (callbackScheduledPhoneCall) {
      setScheduledPhoneCall((prevScheduledPhoneCall) => ({
        ...prevScheduledPhoneCall,
        callbackTime: new Date(callbackScheduledPhoneCall.scheduledFor),
        callbackDistributionStrategy: callbackScheduledPhoneCall.distributionStrategy,
      }));
    }
  }, [scheduledPhoneCall.id])

  return (
    <Segment>
      <h3>Outcome</h3>
      <Form.Field required>
        <label>Call Outcome</label>
        <Dropdown
          error={displayErrors && scheduledPhoneCall.phoneCallOutcomeId == null}
          fluid
          selection
          options={outcomes.map(o => ({ key: o.id, value: o.id, text: o.name }))}
          style={{ marginBottom: 10 }}
          value={scheduledPhoneCall.phoneCallOutcomeId}
          onChange={(_e, element) => handleScheduledPhoneCallChange(_, { name: 'phoneCallOutcomeId', value: element.value })}
          id="phone-call-outcome-dropdown"
        />
      </Form.Field>

      <Form.Field>
        <Form.Field
          control={TextArea}
          label='Call Notes'
          name='notes'
          value={scheduledPhoneCall.notes || ''}
          onChange={handleScheduledPhoneCallChange}
        />
      </Form.Field>

      <Form.Field>
        <MotionDatePicker
          range={scheduledPhoneCall.callbackTime instanceof Date ? "custom" : scheduledPhoneCall.callbackTime}
          from={scheduledPhoneCall.callbackTime instanceof Date ? scheduledPhoneCall.callbackTime : null}
          label={<>Callback Time  {!present(scheduledPhoneCall.prospect) && (
            <Popup
              content="You must assign prospect to set callback time."
              position="top center"
              trigger={<Icon name='info circle' color='blue' />}
            />
          )}{callbackHandledPopup}</>}
          onChange={handleCallbackTimeChange}
          showTimeSelect={true}
          disabled={!present(scheduledPhoneCall.prospect) || callbackHandled}
          options={[
            { key: 'no_callback', value: 'no_callback', text: 'No callback' },
            { key: 'in_3_days', value: 'in_3_days', text: 'In 3 days' },
            { key: 'in_7_days', value: 'in_7_days', text: 'In 7 days' },
            { key: 'custom', value: 'custom', text: 'Custom' }
          ]}
          id="callback-time-dropdown"
          minDate={new Date()}
        />
      </Form.Field>

      {scheduledPhoneCall.callbackTime !== 'no_callback' &&
        <Form.Field>
          <label>
            Who can handle this callback?
            <Popup
              content="Callback has been handled and cannot be modified."
              position="top center"
              trigger={<Icon name='info circle' color='blue' style={{ marginLeft: 5 }} />}
              on="hover"
            />
          </label>
          <Dropdown
            fluid
            selection
            options={[
              { key: 'self', value: 'self', text: 'Just me' },
              { key: 'any', value: 'any', text: 'Anyone' },
            ]}
            style={{ marginBottom: 10 }}
            value={scheduledPhoneCall.callbackDistributionStrategy}
            onChange={(_e, element) => handleScheduledPhoneCallChange(_, { name: 'callbackDistributionStrategy', value: element.value })}
            disabled={callbackHandled}
            id="phone-call-callbackDistributionStrategy-dropdown"
          />
        </Form.Field>
      }

      {/* Effectively it applies only to the incoming scheduled phone calls */}
      {!present(scheduledPhoneCall.completedAt) && futureScheduledPhoneCalls.length > 0 && (
        <Form.Field>
          <label>
            What to do with future scheduled phone calls?
            <Popup
              position="top center"
              trigger={<Icon name='info circle' color='blue' style={{ marginLeft: 5 }} />}
            >
              <p><b>Future scheduled phone calls dates: </b></p>
              {futureScheduledPhoneCalls.map(call => moment(call.scheduledFor).format('DD.MM.YYYY HH:mm')).join(', ')}
            </Popup>
          </label>
          <Dropdown
            error={displayErrors && scheduledPhoneCall.futureScheduledPhoneCalls == null}
            fluid
            selection
            options={
              [
                { key: 'keep', value: 'keep', text: 'Keep future scheduled phone calls unchanged' },
                { key: 'complete', value: 'complete', text: 'Mark future phone calls as completed' }
              ]
            }
            value={scheduledPhoneCall.futureScheduledPhoneCalls || 'keep'}
            id="future-scheduled-phone-calls-dropdown"
            onChange={(_e, element) => handleScheduledPhoneCallChange(_, { name: 'futureScheduledPhoneCalls', value: element.value })}
          />
        </Form.Field>
      )}

      <Form.Field>
        <label>
          Blacklist Prospect
          <Popup
            content="You must assign an prospect to enable blacklisting."
            position="top center"
            trigger={<Icon name='info circle' color='blue' style={{ marginLeft: 5 }} />}
          />
        </label>
        <Dropdown
          fluid
          selection
          options={[{ key: 'no', value: false, text: 'No' }, { key: 'yes', value: true, text: 'Yes' }]}
          style={{ marginBottom: 10 }}
          value={scheduledPhoneCall.blacklistProspect || false}
          onChange={(_e, element) => handleScheduledPhoneCallChange(_, { name: 'blacklistProspect', value: element.value })}
          id="blacklist-prospect-dropdown"
          disabled={!present(scheduledPhoneCall.prospect)}
        />
      </Form.Field>

    </Segment>
  )
}

export default ScheduledPhoneCallControls
