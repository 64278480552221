import React from "react";
import { Header, Table, Form, Button, Icon, } from 'semantic-ui-react'
import CrmTaskForm from "./CrmTaskForm.js";
import BigCheckmark from "./BigCheckmark.js";
import axios from "axios";
import { present, renderProspectCampaignAssocForTableView, } from "./utils.js";
import moment from "moment";
import CrmTaskFormSimpleOpenClose from "./CrmTaskFormSimpleOpenClose.js";


export default class CrmTaskListWithUnroutedModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <React.Fragment>
         { this.props.crmTasks.length > 0 && <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{textAlign: "center"}}>Status</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              {!this.props.hideAssociatedWith && (
                <Table.HeaderCell>Associated with</Table.HeaderCell>
              )}
              <Table.HeaderCell>Assigned to</Table.HeaderCell>
              <Table.HeaderCell>Threads</Table.HeaderCell>
              <Table.HeaderCell>Due date</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.props.crmTasks.map(crmTask => {
                return <CrmTaskListItem
                  key={crmTask.id}
                  crmTask={crmTask}
                  users={this.props.users}
                  onCrmTaskRefreshed={this.props.onCrmTaskRefreshed}
                  hideAssociatedWith={this.props.hideAssociatedWith}
                  handlePopupActivation={this.props.handlePopupActivation}
                  activePopup={this.props.activePopup}
                /> })
            }
          </Table.Body>
        </Table>
         }
      </React.Fragment>

    )
  }
}


class CrmTaskListItem extends React.Component {

  constructor(props) {
    super(props)
    this.handleCompletedClick = this.handleCompletedClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }


  handleCompletedClick(e) {
    axios.post(`/crm_tasks/${this.props.crmTask.id}/update`, {
      crmTask: { id: this.props.crmTask.id, completed: e.target.value, },
    })
      .then(({ data }) => {
        this.props.onCrmTaskRefreshed(data)
      })
      .catch(error => {
        console.log("error submitting", error);
      });
  }

  handleDelete() {
    if (!window.confirm("Do you really want to delete this task?")) {
      return;
    }
    axios.delete(`/crm_tasks/${this.props.crmTask.id}`)
      .then(({ data }) => {
        if (data.success) {
          this.setState({ deleted: true })
          this.props.onCrmTaskRefreshed()
        }
      })
      .catch(error => {
        console.log("error submitting", error);
        this.setState({ deleteLoading: false, deleteErrorMessage: "Could not delete task. We don't know why this error occured. Please try again or contact us :).", })
      });
  }

  l(date) {
    if (!present(date)) return "-"
    return moment(date).format("DD.MM.YYYY")
  }

  isDue(date) {
    return moment(date).isBefore(moment.now())
  }


  render() {
    let { crmTask, users, hideAssociatedWith } = this.props;
    let { prospect } = crmTask;
    let taskAssignee = users.find(u => u.id === crmTask.userId)
    return (
      <Table.Row>
        <Table.Cell>
          <BigCheckmark
            name="completed"
            value={crmTask.completed}
            onChange={this.handleCompletedClick}
          />
        </Table.Cell>
        <Table.Cell>
          { crmTask.title }
        </Table.Cell>
        {!hideAssociatedWith && (
          <Table.Cell>
            {prospect.name}
          </Table.Cell>
        )}
        <Table.Cell>
          { present(taskAssignee) ? taskAssignee.email : "-" }
        </Table.Cell>
        <Table.Cell>
          {
            prospect.prospectCampaignAssociations.map((assoc,i) => renderProspectCampaignAssocForTableView(assoc, prospect, `task:${crmTask.id}-assoc:${assoc.id}`, "left", this.props.handlePopupActivation, this.props.activePopup))
          }
        </Table.Cell>
        <Table.Cell>
          <span className={this.isDue(crmTask.dueDate) ? "red" : ""}>
            { this.l(crmTask.dueDate) }
          </span>
        </Table.Cell>
        <Table.Cell style={{ textAlign: "center" }}>
          <div className="ui mini icon labeled vertical buttons">
            <CrmTaskFormSimpleOpenClose
              defaultCrmTask={present(crmTask) ? crmTask : { id: this.props.match.params.taskId }}
              defaultProspects={present(crmTask) ? [crmTask.prospect] : []}
              users={this.props.users}
              onSave={this.props.onCrmTaskRefreshed}
              trigger={<Button labelPosition='left' size='mini' icon onClick={this.handleOpen}><Icon name="edit" ></Icon>Edit</Button>}
            />
            <Button icon='trash' content='Delete' onClick={this.handleDelete} />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
}
