import React from "react";

import { Header, Table, Form, Button, Icon, Dropdown, Loader, Segment, Grid, Message } from 'semantic-ui-react'
import { present, formInput } from "../utils";
import ProspectCrmStatusChange from "../ProspectCrmStatusChange";
import CrmTaskFormSimpleOpenClose from "../CrmTaskFormSimpleOpenClose";
import CrmTaskListWithUnroutedModal from "../CrmTaskListWithUnroutedModal";
import PhoneCalls from "./PhoneCalls";
import ManuallyRequestedLinkedInOutreachFormSimpleOpenClose from "../ManuallyRequestedLinkedInOutreachFormSimpleOpenClose";
import ManuallyRequestedLinkedInOutreachList from "../ManuallyRequestedLinkedInOutreachList";
import CallSegment from "./CallSegment";

const ModalView = (props) => {
  const { data, handlers } = props;
  const prospect = data.prospect

  let tags = data.tags;
  tags ||= [];

  return (
    <React.Fragment>
      <h3 style={{ lineHeight: "42.2px" }}> {handlers.renderCompanyLink(prospect)} - {prospect.title} </h3>

      {data.dataProviderInformation.map((dpi, i) => {
        if (data.addDataProviderInformation.id == dpi.id) return null;

        return (
          <div key={i} style={{ minHeight: 40 }}>
            {dpi.type == "phone" && (
              <>
                <i className="fa-solid fa-phone" style={{ marginRight: 5 }}></i>
                <a
                  href={`tel:${dpi.value}`}
                  title={(!present(dpi.userSanitizedValue) && dpi.value !== dpi.originalValue) ? `Original value: ${dpi.originalValue}` : undefined}
                >
                  {dpi.userSanitizedValue || dpi.value}
                </a>
              </>
            )}
            {dpi.type == "email" && (
              <>
                <i className="fa-solid fa-envelope" style={{ marginRight: 5 }}></i>
                <a href={`mailto:${dpi.value}`}>{dpi.userSanitizedValue || dpi.value}</a>
              </>
            )}
            <span style={{ marginLeft: 5, color: 'gray' }}>({dpi.source})</span>
            {dpi.dataProvider === 'User' && (
              <i
                className="fa-solid fa-pen"
                style={{ marginLeft: 5, cursor: 'pointer' }}
                onClick={() => handlers.setState({ addDataProviderInformation: { id: dpi.id, type: dpi.type, userSanitizedValue: dpi.userSanitizedValue, value: dpi.value } })}
              ></i>
            )}
            {present(data.currentUserTwilioPhoneNumber) && dpi.type == "phone" && (
              <CallSegment
                openCallSegment={data.openCallSegment}
                handlers={handlers}
                dataProviderInformation={dpi}
                prospectId={prospect.id}
              />
            )}
          </div>
        )
      })}

      {!present(data.addDataProviderInformation) && (
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <Button
            content="Add Phone Number"
            size="mini"
            onClick={() => handlers.setState({ addDataProviderInformation: { type: 'phone' } })}
          />
          <Button
            content="Add Email Address"
            size="mini"
            onClick={() => handlers.setState({ addDataProviderInformation: { type: 'email' } })}
          />
        </div>
      )}

      {present(data.addDataProviderInformation) && (
        <Form.Field style={{ marginBottom: 10 }}>
          <Form.Input type='text' action>
            <i
              className={`fa-solid fa-${data.addDataProviderInformation.type == 'email' ? 'envelope' : 'phone'}`}
              style={{ marginRight: 5, marginTop: 12 }}
            ></i>
            <input
              type={data.addDataProviderInformation.type == 'email' ? 'email' : 'tel'}
              value={data.addDataProviderInformation?.userSanitizedValue || data.addDataProviderInformation?.value || ''}
              onChange={(e) => handlers.setState({ addDataProviderInformation: { ...data.addDataProviderInformation, value: e.target.value, userSanitizedValue: e.target.value, } })}
              onKeyDown={(e) => e.key == 'Enter' && handlers.handleDataProviderInformationCreation(data.addDataProviderInformation)}
            />
            <Button onClick={() => handlers.handleDataProviderInformationCreation(data.addDataProviderInformation)}>Save</Button>
            {data.addDataProviderInformation.id && (
              <Button
                color="red"
                onClick={() => handlers.handleDataProviderInformationDeletion(data.addDataProviderInformation)}
              >
                Delete
              </Button>
            )}
            <Button basic onClick={() => handlers.setState({ addDataProviderInformation: {} })}>Cancel</Button>
          </Form.Input>
        </Form.Field>
      )}

      <Form>
        <ProspectCrmStatusChange
          prospect={prospect}
          crmStatuses={data.crmStatuses}
          users={data.users}
          onProspectChange={handlers.onProspectChangeWithoutNotes}
          label={"Crm Status"}
        />
        <Form.Field>
          <label>Tags</label>
          <Dropdown
            multiple
            search
            options={
              data.tags.map(tag => { return { key: tag.id, text: tag.name, value: tag.id } }).sort(
                (a, b) => a.text.localeCompare(b.text)
              )
            }
            value={prospect.tagIds}
            allowAdditions
            onAddItem={(_, { value }) => handlers.handleTagCreation(value)}
            selection
            onChange={(_, { value }) => handlers.setState({ prospect: { ...prospect, tagIds: value.filter(v => !isNaN(v)) } })}
            selectOnBlur={false}
            clearSearchQueryAfterItemAdd={true}
          />
        </Form.Field>
        {
          formInput(
            prospect,
            "notes",
            "Notes",
            handlers.onProspectFormChange,
            Form.TextArea,
          )
        }
      </Form>

      {present(prospect.prospectCampaignAssociations) && <h2>Campaigns</h2>}
      {prospect.prospectCampaignAssociations.map((assoc, i) => {
        return <Segment key={i}>
          <h3>{assoc.campaign.name}</h3>
          {handlers.renderAssoc(assoc, prospect, i, data.users)}
        </Segment>
      })}

      {present(prospect.crmStatusChanges) &&
        <React.Fragment>
          <h2>Status History</h2>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Received at</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {prospect.crmStatusChanges.map((change, i) => {
                return <Table.Row key={i}>
                  <Table.Cell style={{ width: "50%" }}>{change.crmStatusAfter ? change.crmStatusAfter.name : <i>no status</i>}</Table.Cell>
                  <Table.Cell style={{ width: "50%" }}>{handlers.lHour(change.changedAt)}</Table.Cell>
                </Table.Row>
              })}
            </Table.Body>
          </Table>
        </React.Fragment>
      }

      <PhoneCalls phoneCalls={data.phoneCalls || []} setState={handlers.setState} />

      <Grid style={{ marginTop: 30 }}>

        <Grid.Row>
          <Grid.Column width={13}>
            <Header as='h2' floated='left'>
              Tasks
            </Header>
          </Grid.Column>
          <Grid.Column width={3} float="right" style={{ textAlign: "right" }}>
            <CrmTaskFormSimpleOpenClose
              trigger={<Button size="mini" labelPosition='left' icon><Icon name="add"></Icon>Create Task</Button>}
              onSave={handlers.onNewTaskSaved}
              defaultCrmTask={{ prospectId: prospect.id, userId: data.currentUserId, }}
              defaultProspects={[prospect]}
              users={data.users}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>


      {data.crmTasks.length > 0 && <CrmTaskListWithUnroutedModal
        crmTasks={data.crmTasks}
        users={data.users}
        onCrmTaskRefreshed={handlers.onCrmTaskRefreshed}
        activePopup={data.activePopup}
        handlePopupActivation={handlers.handlePopupActivation}
      />
      }

      {data.crmTasks.length === 0 && data.tasksDidLoadOnce && <Message icon>
        <Icon name='filter' />
        <Message.Content>
          <Message.Header>No tasks for this prospect.</Message.Header>
        </Message.Content>
      </Message>
      }
      {(prospect.manuallyRequestedLinkedInOutreaches.concat(prospect.triggeredManuallyRequestedLinkedInOutreaches)).length > 0 &&
        <Grid style={{ marginTop: 30 }}>

          <Grid.Row>
            <Grid.Column width={13}>
              <Header as='h2' floated='left'>
                Manual LinkedIn connection requests
              </Header>
            </Grid.Column>
            <Grid.Column width={3} float="right" style={{ textAlign: "right" }}>
              <ManuallyRequestedLinkedInOutreachFormSimpleOpenClose
                trigger={<Button size="mini" labelPosition='left' icon><Icon name="add"></Icon>Create request</Button>}
                campaigns={data.campaigns}
                onSave={handlers.onManuallyRequestedLinkedInOutreachAdded}
                defaultRequestedOutreach={{
                  triggeringProspectId: prospect.id,
                  triggeringProspect: {
                    id: prospect.id,
                    name: prospect.name,
                  },
                  message: "",
                  followUps: [],

                }}
                users={data.users}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>

              {prospect.manuallyRequestedLinkedInOutreaches.length > 0 &&
                <React.Fragment>
                  <Header as='h3' floated='left'>
                    ..to this prospect
                  </Header>
                  <ManuallyRequestedLinkedInOutreachList
                    manuallyRequestedLinkedInOutreaches={prospect.manuallyRequestedLinkedInOutreaches}
                    campaigns={data.campaigns}
                    onManuallyRequestedLinkedInOutreachRefreshed={handlers.onManuallyRequestedLinkedInOutreachChange}
                    onManuallyRequestedLinkedInOutreachRemoved={handlers.onManuallyRequestedLinkedInOutreachRemoved}
                    routed={false}
                    crmStatuses={data.crmStatuses}
                  />
                </React.Fragment>
              }
              {prospect.triggeredManuallyRequestedLinkedInOutreaches.length > 0 &&
                <React.Fragment>
                  <Header as='h3' floated='left'>
                    ..from this prospect
                  </Header>
                  <ManuallyRequestedLinkedInOutreachList
                    manuallyRequestedLinkedInOutreaches={prospect.triggeredManuallyRequestedLinkedInOutreaches}
                    campaigns={data.campaigns}
                    onManuallyRequestedLinkedInOutreachRefreshed={handlers.onManuallyRequestedLinkedInOutreachChange}
                    onManuallyRequestedLinkedInOutreachRemoved={handlers.onManuallyRequestedLinkedInOutreachRemoved}
                    routed={false}
                    crmStatuses={data.crmStatuses}
                  />
                </React.Fragment>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>

      }

      {data.loading && <Loader active inline='centered' />}


    </React.Fragment>
  );
}

export default ModalView;
