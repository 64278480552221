import React from "react";
import CampaignMessagesForm from "../backend/CampaignMessagesForm.js";
import { Form, Button, Dropdown, Grid, Segment, Icon, Message, Header, Progress, Loader, Modal, } from 'semantic-ui-react';
import { present, errorFor, formInput, adaptOnChangeToClassicFormOnChange } from "../shared/utils.js";
import axios from "axios";

export default class ManuallyRequestedLinkedInOutreachFormPresentation extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}
  }


  handleOnClose(event) {
    if (event.key === "Escape") {
      this.props.onForceClose();
    } else {
      this.props.onClose(event);
    }
  }

  getErrorMessage(requestedOutreach, maxConnectionMessageLength) {
    if (requestedOutreach.error === "MESSAGE_TOO_LONG") {
      return `This connection request could not be sent, because the account has a ${maxConnectionMessageLength} character limit. Either shorten the connection message or send the request as an empty invitation.`
    }

    return requestedOutreach.error
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.open === false && nextProps.open === false) {
      return false;
    }
    return true
  }

  render () {
    let {
      onChange, loading, onSubmit, requestedOutreach, errorMessage, trigger, campaigns, successMessage,
      onOpen, open, onForceClose, defaultProspects, onMessagesChange, renderTemplateVariables, prefillMessages
    } = this.props
    let triggeringProspect = requestedOutreach.triggeringProspect || {}

    let onClose = this.handleOnClose.bind(this)
    let campaign = campaigns.find(c => c.id === requestedOutreach.campaignId);
    let gmailMailboxId = campaign ? campaign.gmailMailboxId : null
    let maxConnectionMessageLength = campaign ? campaign.maxConnectionMessageLength : 300

    let allowEditingFromStage = 0;
    if (present(requestedOutreach.linkedInOutreach)) {
      allowEditingFromStage = requestedOutreach.linkedInOutreach.followUpMessages.length+1;
    }

    if (requestedOutreach.errorNotCorrectable && present(requestedOutreach.error)) {
      return (
        <React.Fragment>
          <Modal trigger={trigger} onClose={onClose} onOpen={onOpen} open={open}>
            <Modal.Header>
              <Grid>
                <Grid.Column width={15}>
                  { present(requestedOutreach.id) ? "Edit connection request" : "Create connection request" }
                  { present(triggeringProspect.name) && ` triggered from ${triggeringProspect.name}` }
                </Grid.Column>
                <Grid.Column width={1}>
                  <Icon name="close" onClick={onForceClose} style={{cursor: "pointer"}}/>
                </Grid.Column>
              </Grid>
            </Modal.Header>
            <Modal.Content>
              { <Message
                header={requestedOutreach.errorNotCorrectable ? "This request cannot be edited as we already attempted to send it and it failed." : "An error occured that you can fix" }
                content={this.getErrorMessage(requestedOutreach, maxConnectionMessageLength)}
              /> }
            </Modal.Content>
          </Modal>
        </React.Fragment>
      )
    }

    return (

      <React.Fragment>

        <Modal trigger={trigger} onClose={onClose} onOpen={onOpen} open={open}>
          <Modal.Header>
            <Grid>
              <Grid.Column width={15}>
                { present(requestedOutreach.id) ? "Edit connection request" : "Create connection request" }
                { present(triggeringProspect.name) && ` triggered from ${triggeringProspect.name}` }
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon name="close" onClick={onForceClose} style={{cursor: "pointer"}}/>
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>

            { present(requestedOutreach.error) && !requestedOutreach.errorNotCorrectable &&
              <Message
                header={"An error occured that you can fix" }
                content={this.getErrorMessage(requestedOutreach, maxConnectionMessageLength)}
              />
            }
            { errorMessage && <Message
              error
              header={"An error occured"}
              content={errorMessage}
            /> }
            {successMessage && present(requestedOutreach.errors) &&
                <Message
                  error
                  header={"Invalid"}
                  content={"Please correct the entries marked in red."}
                />
            }
            {successMessage && !present(requestedOutreach.errors) &&
                <Message
                  success
                  header={"Success"}
                  content={successMessage}
                />
            }

            <Form>

              <Grid columns='equal'>
                <Grid.Column width={8}>
                  { formInput(requestedOutreach, "linkedInProfileUrl", "Profile Url", onChange, Form.Input, { autoFocus: true }) }
                </Grid.Column>
                <Grid.Column width={8}>
                  { formInput(requestedOutreach, "name", "Name", onChange, Form.Input) }
                </Grid.Column>
                <Grid.Column width={16}>
                  <Grid>
                      { /* TODO Remove the status filter once we have LinkedIn Account execution level rolled out*/ }
                      <Grid.Column width={16}>
                        {
                          formInput(
                            requestedOutreach,
                            "campaignId",
                            "Campaign",
                            onChange,
                            Form.Select,
                            {
                              options: campaigns.filter(c => c.status !== '3' && c.status !== '5').map(c => { return { key: c.id, value: c.id, text: c.name } }),
                              search: true,
                              clearSearchQueryAfterItemAdd: true,
                            }
                          )
                        }
                      </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={16} style={{marginBottom: 16}}>
                  { formInput(requestedOutreach, "sendAsEmptyInvitation", "Send as empty invitation", onChange, Form.Checkbox, { slider: true } ) }
                </Grid.Column>
                <Grid.Column width={16}>
                  <CampaignMessagesForm
                    gmailMailboxId={gmailMailboxId}
                    maxConnectionMessageLength={requestedOutreach.sendAsEmptyInvitation ? null : maxConnectionMessageLength}
                    messages={requestedOutreach}
                    onMessagesChange={onMessagesChange}
                    genderedSalutes={[]}
                    renderTemplateVariables={renderTemplateVariables}
                    customPromptVariables={this.props.customPromptVariables || {}}
                    renderPreview={false}
                    allowEditingFromStage={allowEditingFromStage}
                    headlineFirstMessage={requestedOutreach.sendAsEmptyInvitation ? "Message after empty invitation" : null}
                    followUpName={requestedOutreach.sendAsEmptyInvitation ? "Follow up for empty invitation" : null}
                    sendsEmptyInvitations={requestedOutreach.sendAsEmptyInvitation}
                    prefillMessages={prefillMessages}
                    manuallyRequestedLinkedInOutreach={true}
                  />

                </Grid.Column>

                <Grid.Column width={16}>
                  { /* Hidden Submit Button first so this button is triggered on enter in a form field */ }
                  <Button
                    style={{display: "none"}}
                    onClick={onSubmit}
                    loading={loading}
                  ></Button>
                  <Button.Group>
                   <Button
                      loading={loading}
                      onClick={onForceClose}
                    >Cancel</Button>
                    <Button.Or />
                    <Button
                      type="submit"
                      positive
                      onClick={onSubmit}
                      loading={loading}
                    >Save &amp; Close</Button>
                  </Button.Group>
                </Grid.Column>
              </Grid>
            </Form>

          </Modal.Content>

        </Modal>
      </React.Fragment>

    )
  }
}
