import React, { useState, useEffect } from 'react';
import { Device } from '@twilio/voice-sdk';
import TwilioCall from './TwilioCall';

const initializeDevice = async (setDevice, setIncomingCalls = null) => {
  try {
    const response = await fetch('/twilio/token');
    const data = await response.json();
    const newDevice = new Device(data.token, {
      logLevel: 3,
      // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
      // providing better audio quality in restrained network conditions.
      codecPreferences: ["opus", "pcmu"],
      allowIncomingWhileBusy: true,
    });

    newDevice.on('error', (error) => {
      console.error('Device error:', error);
    });

    newDevice.on('tokenWillExpire', async () => {
      const response = await fetch('/twilio/token');
      const data = await response.json();
      newDevice.updateToken(data.token);
    })

    newDevice.on('unregistered', async () => {
      console.log('Twilio device unregistered, re-registering');
      initializeDevice(setDevice, setIncomingCalls);
    });

    if (setIncomingCalls) {
      newDevice.on('incoming', (connection) => {
        setIncomingCalls(connection, 'add');

        connection.on('cancel', () => {
          setIncomingCalls(connection, 'remove');
        })

        connection.on('reject', () => {
          setIncomingCalls(connection, 'remove');
        })

        connection.on('disconnect', () => {
          setIncomingCalls(connection, 'remove');
        })
      });
    }

    newDevice.register()

    setDevice(newDevice);
  } catch (error) {
    console.error('Failed to initialize device:', error);
  }
}

const TwilioDevice = (props) => {
  const [device, setDevice] = useState(props.device);

  useEffect(() => {
    if (!device) {
      initializeDevice(setDevice);
    }
  }, []);

  return (
    <TwilioCall {...props} device={device} />
  );
};

export { TwilioDevice, initializeDevice };
